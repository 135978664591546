<template lang="">
  <div v-if="showTable">
    <basetable
      v-if="renderComp"
      :dataurl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :title="title"
      :tombol_hide="tombol_hide"
      :search_hide="search_hide"
      :page_size_hide="true"
      :no_margin="true"
      :is-edit="false"
      :is-delete="userType != 'vendor'"
      :custom_action="userType != 'vendor'"
      @showData="showData"
    >          
      <template v-slot:buttonaddon>
        <b-button
          v-if="userType != 'vendor'"
          class="mb-1 mr-1"
          variant="success"
          type="button"
          @click.prevent="tampilForm()"
        >
          Tambah Denda {{ item_id }}
        </b-button>
        <b-button
          class="mb-1"
          variant="primary"
          type="button"
          @click.prevent="print()"
        >
          Print
        </b-button>
      </template>
      <template #cell(price_item)="data">
        Rp {{ getPrice(data.item).item| numFormat }}
      </template>
      <template #cell(price_maintenance)="data">
        Rp {{ getPrice(data.item).maintenance| numFormat }}
      </template>
      <template #cell(prosentase)="data">
        {{ getProsentase(data.item) }} %
      </template>
      <template #cell(amount)="data">
        Rp {{ getTotal(data.item)| numFormat }}
      </template>
    </basetable>
  </div>
  <div
    v-else
    class="m-1 mt-3"
  >
    <h3 class="mb-2">
      {{ form_title }}
    </h3>
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Nopol Kendaraan</label>
          </div>
          <div class="col-md-5">
            <v-select
              v-model="fields_form.vehicle"
              label="number"
              placeholder="Select Kendaraan"
              :options="vehicleItem"
              required="required"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Selisih Hari</label>
          </div>
          <div class="col-md-9">
            <input
              v-model="fields_form.duration"
              type="number"
              placeholder="Selisih Hari"
              class="form-control"
              required=""
            >
          </div>
        </div>
      </div>
      <b-button
        class="mr-1"
        size="md"
        variant="primary"
        type="submit"
        @click.prevent="btnSimpan()"
      >
        <span>Simpan</span>
      </b-button>
      <b-button
        class="mr-1"
        size="md"
        variant="outline-secondary"
        type="button"
        @click.prevent="tampilTable()"
      >
        <span>Batal</span>
      </b-button>
    </form>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleDataV2.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import {
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    baseSelectSingle,
    BButton,
    BCard,
    FormVSelect,
    flatPickr,
    vSelect,
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      userType:null,
      dataurl: this.posturl+"/"+this.$route.params.id+"/penalty",
      baseroute:"spb",
      title:"",
      form_title:null,
      tombol_hide:true,
      search_hide: true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'vehicle.number', label: 'Nomor Polisi', sortable: true },
        { key: 'vehicle.variant.type.name', label: 'Type', sortable: true },
        { key: 'vehicle.variant.manufacture.name', label: 'Pabrikan', sortable: true },
        { key: 'vehicle.variant.model', label: 'Model', sortable: true },
        { key: 'price_item', label: 'Harga Satuan', sortable: true },
        { key: 'price_maintenance', label: 'Biaya Pemeliharaan', sortable: true },
        { key: 'duration', label: 'Selisih Hari', sortable: true },
        { key: 'prosentase', label: 'Denda (%)', sortable: true },
        { key: 'amount', label: 'Denda', sortable: true },
      ],
      fields_form :{
        vehicle:null,
        vehicle_id:null,
        duration:null,
      },
      model:{}, 
      vehicleItem: [],
      penalty:null
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    showData(data){
      this.penalty = data
      this.$emit('showData', data)
    },
    getData(){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        this.model = res.data
      })
      this.$http.get('/spb/'+this.$route.params.id+'/vehicle?length=').then(res=>{
        const vehicle = []
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          if(data[i].vehicle_id)
            vehicle.push({
              id: data[i].vehicle_id,
              number: data[i].number_plate + ' - ' + data[i].variant.manufacture.name + ' - ' + data[i].variant.model,
            })
        }
        this.vehicleItem = vehicle
      })
     },
    tampilForm(){
      this.showTable = false
      this.form_title = "Tambah Denda"
      this.valid_form = 'tambah'
      this.resetForm()
    },
    tampilTable(){
      this.showTable = true
    },
    btnSimpan(){
      var message = ''
      if(!this.fields_form.vehicle){
        message = 'The vehicle field is required'
      }else if(!this.fields_form.duration){
        message = 'The duration field is required'
      }
      
      if(message != '')
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Form Error',
            text: message,
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
        
      this.filterDataSubmit()
      var http;
      if(this.valid_form == 'tambah'){
        http = this.$http.post('/spb/'+this.$route.params.id+'/penalty',this.fields_form)
      }else{
        http = this.$http.put('/spb/'+this.$route.params.id+'/penalty/'+this.id_item,this.fields_form)
      }

      http.then(()=>{
        this.resetForm()
        this.showTable = true
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submit Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(err => {
        const msg = err.response.data.message
        this.$bvToast.toast((msg)?msg:'Submit Error', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
      })
      
    },
    editForm(data){
      this.fields_form.vehicle = data.item.vehicle
      this.fields_form.duration = data.item.duration
      this.showTable = false
      this.form_title = "Edit Item Barang"
      this.valid_form = 'edit'
      this.id_item = data.item.id
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm(data.item.vehicle != null ? 'Anda Ingin Menghapus Data '+data.item.vehicle.number +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                })
            })
          }
        })
    },
    resetForm(){
      this.fields_form = {
        vehicle:null,
        vehicle_id:null,
        duration:null
      }
    },
    async filterDataSubmit() {
      let newData ={}
      // console.log(this.model)
        if(typeof this.fields_form.vehicle === 'object'){
          if(Array.isArray(this.fields_form.vehicle)){
            let arrData = this.fields_form.vehicle.map(e => e.id)
            newData[this.fields_form.variant] = arrData
          }else{
            newData['vehicle_id'] = this.fields_form.vehicle.id
            this.fields_form.vehicle_id = this.fields_form.vehicle.id
          }
        }
    },
    getPrice(data){
      var price_item = 0
      var price_maintenance = 0

      if(data.vehicle){
        var vehicle = data.vehicle
        var items = data.items
        items.forEach(item =>{
          if(vehicle.variant_id == item.variant_id){
            price_item += item.price
            price_maintenance += item.price_maintenance
          }
        })
      }

      return {item:price_item, maintenance:price_maintenance}
    },
    getTotal(data){
      var price = 0
      var duration = data.duration

      if(data.vehicle){
        var vehicle = data.vehicle
        var items = data.items
        items.forEach(item =>{
          if(vehicle.variant_id == item.variant_id){
            price = parseInt(item.price)+parseInt(item.price_maintenance)
          }
        })
      }

      if(duration > 50)
        duration = 50

      var ppn = 10/100*price
      price = duration/1000*(price+ppn)
      return price
    },
    getProsentase(data){
      var price = 0
      var duration = data.duration
      var prosentase = 0

      if(data.vehicle){
        var vehicle = data.vehicle
        var items = data.items
        items.forEach(item =>{
          if(vehicle.variant_id == item.variant_id){
            price = parseInt(item.price)+parseInt(item.price_maintenance)
          }
        })
      }

      if(duration > 50)
        duration = 50

      var ppn = 10/100*price
      prosentase = (duration/1000*(price+ppn))/(price+ppn)*100
      return prosentase.toFixed(2)
    },
    print(){
      if(this.penalty.length > 0){
        this.$http.get('spb/'+this.$route.params.id+'/badk/print').then(res =>{
          window.open(res.data.filename)
        }).catch(err =>{
          var msg = err.response.data.message
          return this.$bvToast.toast(msg ? msg : 'Print Error', {
            title: 'Error',
            solid: true,
            variant: 'danger',
          })
        })
      }
      else
        return this.$bvToast.toast('Denda is empty', {
            title: 'Error',
            solid: true,
            variant: 'danger',
          })
    }
  }
}
</script>
<style lang="">

</style>
