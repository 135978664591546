<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col cols="4">
              <h4>Data SPB</h4>
            </b-col>
            <b-col cols="8" class="mb-2 d-flex flex-row-reverse">
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-dropdown class="dropdown-scroll" text="BA Perawatan" variant="success" size="sm" v-if="periodePayments.length > 0 && !loading">
                  <b-dropdown-item class="pl-1 pr-1 mb-1" v-for="periodePayment in periodePayments" :key="periodePayment">
                    <button 
                      class="btn btn-primary btn-sm w-100"
                      @click.prevent="printMaintenanceDoc(periodePayment)"
                    >
                      {{ periodePayment }}
                    </button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <b-col cols="12">
              <form-label label="Kode SPB" :content="model.number" />
            </b-col>
            <b-col cols="12">
              <form-label label="Tanggal SPB" :content="model.date" />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Region"
                :content="model.region != null ? model.region.name : ''"
              />
            </b-col>
            <b-col cols="12">
              <form-label label="Judul Kontrak" :content="model.contract_subject" />
            </b-col>
            <b-col cols="12">
              <form-label label="Nomor Kontrak" :content="model.contract_number" />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Vendor"
                :content="model.vendor != null ? model.vendor.name : ''"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Sumber Dana SKKO"
                :content="model.skko ? model.skko.number : '-'"
              />
            </b-col>
            <b-col cols="12">
              <form-label
                label="Tanggal Waktu Pengiriman"
                :content="model.shipping_date"
              />
            </b-col>
            <b-col cols="12">
              <div class="row">
                <div class="col-5">RAB</div>
                <div class="col">
                  <span
                    class="badge rounded-pill"
                    :class="model.is_rab?'bg-success':'bg-primary'"
                    >{{model.is_rab?'Termasuk RAB':'Non RAB'}}</span
                  >
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="row mt-1">
                <div class="col-5">Status {{ $route.meta.navActiveLink }}</div>
                <div class="col">
                  <span
                    class="badge rounded-pill"
                    :class="status_spb[model.status].color"
                    >{{status_spb[model.status].value}}</span
                  >
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <hr />
              <div v-if="!loading">
                <!-- !role.name.includes('Admin Pusat') -->
                <span>
                  <b-button
                    v-if="model.status"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="printSPB"
                  >
                    <span>{{model.status != 'created' && model.status != 'applied'?'Print SPB':'Preview SPB'}}</span>
                  </b-button>
                </span>
                <span v-if="$route.meta.navActiveLink != 'spb-vendor'">
                  <b-button
                    v-show="model.status == 'created' && userId == model.author_id ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="primary"
                    type="button"
                    @click.prevent="editData(model)"
                  >
                    Edit
                  </b-button>
                  <b-button
                    v-show="
                      model.status == 'created' && userId == model.author_id
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showAjukan"
                  >
                    Ajukan
                  </b-button>
                  <b-button
                    v-show="model.status == 'applied' && user.id == model.pic_id ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showSetuju"
                  >
                    Setujui
                  </b-button>
                  <b-button
                    v-show="
                      model.status == 'send_letter_distribute' &&
                      $route.meta.navActiveLink == 'penerimaan' && vehicle && variantItem || setReceive
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showDistribute"
                  >
                    Terima Unit
                  </b-button>
                  <!-- <b-button
                    v-show="
                      model.status == 'send_letter_distribute' &&
                      $route.meta.navActiveLink == 'penerimaan'
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="warning"
                    type="button"
                    @click.prevent="showDistributeNonSpb"
                  >
                    Terima Tidak Sesuai SPB
                  </b-button>
                  <b-button
                    v-show="model.is_according_to_spb != 'undefined' && model.is_according_to_spb == false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="warning"
                    type="button"
                    @click.prevent="showDistributeSpb"
                  >
                    Terima Sudah Sesuai SPB
                  </b-button> -->

                  <b-button
                    v-show="model.status == 'applied' && user.id == model.pic_id ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="warning"
                    type="button"
                    @click.prevent="showRevisi"
                  >
                    Revisi
                  </b-button>
                  <b-button
                    v-show="
                      model.status == 'applied' && user.id == model.pic_id
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="warning"
                    type="button"
                    @click.prevent="showBatalkan"
                  >
                    Batalkan
                  </b-button>
                  <b-button
                    v-show="model.status == 'created' && userId == model.author_id
                        ? true
                        : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="danger"
                    type="button"
                    @click.prevent="confirmDelete(model)"
                  >
                    Hapus
                  </b-button>
                  <b-button
                    v-show="(model.status == 'created' && model.note_return != null && model.note_return != '') ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="secondary"
                    type="button"
                    @click.prevent="showNote"
                  >
                    Lihat Note
                  </b-button>
                  <b-button
                    v-show="
                      model.status == 'request_rikmatek' && !isPrintRikmatek && rikmatekVariant && vehicle
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showRikmatek"
                  >
                    Lakukan Pemeriksaan Kendaraan
                  </b-button>
                  <b-button
                    v-show="
                      $route.meta.navActiveLink == 'pemeriksaan' || $route.meta.navActiveLink == 'spb' && isPrintRikmatek
                        ? true
                        : false
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="primary"
                    type="button"
                    @click.prevent="printRikmatek"
                  >
                    Print Rikmatek
                  </b-button>
                </span>
                <span v-else>
                  <b-button
                    v-show="model.status == 'viewed' ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showPrepare"
                  >
                    Lengkap
                  </b-button>
                  <!-- <b-button
                    v-show="(model.status == 'delivered') ? true : false"
                    style="margin-right: 5px;"
                    variant="success"
                    type="button"
                    @click.prevent="showDelivered"
                  >
                    Ajukan Rikmatek
                  </b-button> -->
                  <b-button
                    v-show="model.status == 'checked' && vehicle && variantItem || setPlanReceive ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showReceive"
                  >
                    Buat Surat Pengiriman
                  </b-button>
                  <!-- <b-button
                    v-show="model.status == 'prepared' ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showKirim"
                  >
                    Ajukan Rikmatek
                  </b-button> -->
                  <b-button
                    v-show="model.status == 'prepared' ? true : false"
                    style="margin-right: 5px; margin-top: 5px"
                    variant="success"
                    type="button"
                    @click.prevent="showPersiapanUnit"
                  >
                    Persiapan Unit
                  </b-button>
                  <b-button
                    v-show="
                      model.status == 'request_rikmatek' && isPrintRikmatek
                    "
                    style="margin-right: 5px; margin-top: 5px"
                    variant="primary"
                    type="button"
                    @click.prevent="submitRikmatek"
                  >
                    Print Rikmatek
                  </b-button>
                </span>
                <!-- <b-button
                  v-show="(model.status == 'delivered' && $route.meta.navActiveLink == 'pemeriksaan') ? true : false"
                  style="margin-right: 5px;"
                  variant="success"
                  type="button"
                  @click.prevent="showTerima"
                >
                  Diterima
                </b-button>
                <b-button
                  v-show="(model.status == 'delivered' && $route.meta.navActiveLink == 'spb') ? true : false"
                  style="margin-right: 5px;"
                  variant="success"
                  type="button"
                  @click.prevent="showTerima"
                >
                  Diterima
                </b-button> -->

                <!-- <b-button
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="cancel"
                >
                  Kembali
                </b-button> -->
              </div>
            </b-col>
          </b-row>
          <b-modal
            id="modal-center"
            ref="my-modal-delivered"
            :title="title_modal"
            centered
            hide-footer
          >
            <span v-if="data_barang.length == 0">
              <p>Kelengkapan kendaraan belum lengkap</p>
            </span>
            <span v-else>
              <div class="form-group">
                <p>Apa anda ingin mengajukan Rikmatek ini ?</p>
              </div>
            </span>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button
                v-if="data_barang.length != 0"
                variant="warning"
                @click.prevent="submitDelivered"
              >
                Ajukan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            size="lg"
            ref="my-modal-rikmatek"
            :title="title_modal"
            centered
            hide-footer
          >

            <b-form-group label-cols-md="4" label="Item" v-if="rikmatekVariant">
              <v-select
                v-model="rikmatek.variant"
                :label="'model'"
                placeholder="Pilih Item"
                :options="rikmatekVariant"
                required="required"
                @input="changeItem"
              />
            </b-form-group>
            <b-form-group label-cols-md="4" label="Item Pengujian">
              <div class="row">
                <div class="col-md-9">
                  <form-input
                    v-model="item_check"
                    :label="''"
                    :size="'0'"
                    placeholder="Item Pengujian"
                  />
                </div>
                <div class="col-md-2">
                  <b-button variant="success" type="button" @click.prevent="addItemCheck">
                    <span>Tambah</span>
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <b-form-group label-cols-md="4" label="" v-if="arr_item_check.length > 0">
              <div class="row">
                <div class="col-md-4" v-for="(item, index) in arr_item_check" :key="index">
                  <p>{{index+1}}. {{item}} &nbsp;
                    <b-button
                      size="sm"
                      variant="flat-danger"
                      class="mr-1 btn-icon"
                      @click.prevent="rmItemCheck(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </p>
                </div>
              </div>
            </b-form-group>
            <b-form-group label-cols-md="4" label="Hasil">
              <div class="row">
                <div class="col-md-9">
                  <form-input
                    v-model="result"
                    :label="''"
                    :size="'0'"
                    placeholder="Hasil"
                  />
                </div>
                <div class="col-md-2">
                  <b-button variant="success" type="button" @click.prevent="addResult">
                    <span>Tambah</span>
                  </b-button>
                </div>
              </div>
            </b-form-group>
            <b-form-group label-cols-md="4" label="" v-if="arr_result.length > 0">
              <div class="row">
                <div class="col-md-4" v-for="(result, index) in arr_result" :key="index">
                  <p>{{index+1}}. {{result}} &nbsp;
                    <b-button
                      size="sm"
                      variant="flat-danger"
                      class="mr-1 btn-icon"
                      @click.prevent="rmResult(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </p>
                </div>
              </div>
            </b-form-group>

            <!-- <form-input
              v-model="rikmatek.item_check"
              rules="required"
              label="Item Pengujian"
              placeholder="Item Pengujian"
            /> -->

            <!-- <form-input
              v-model="rikmatek.result"
              rules="required"
              label="Hasil"
              placeholder="Hasil"
            /> -->

            <form-input
              v-model="rikmatek.result_check"
              rules="required"
              label="Hasil Pemeriksaan"
              placeholder="Hasil Pemeriksaan"
              :disabled="true"
            />

            <form-input
              v-model="rikmatek.note"
              label="Keterangan"
              placeholder="Keterangan"
            />

            <div class="form-group mt-2 float-right">
              <b-button variant="primary" @click.prevent="addDetailRikmatek">
                Tambah
              </b-button>
            </div>

            <b-table
              striped
              responsive
              :items="rikmatek.details"
              :fields="fieldRikItem"
            >
              <template #cell(item_check)="data">
                {{splitItem(data.item.item_check)}}
              </template>
              <template #cell(result)="data">
                {{splitItem(data.item.result)}}
              </template>
              <template #cell(_)="data">
                <b-button
                  size="sm"
                  variant="flat-danger"
                  class="mr-1 btn-icon"
                  @click.prevent="rmDetail(data)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>

            <div class="form-group">
              <!-- <p>Apa kelengkapan kendaraan sudah lengkap ?</p> -->
            </div>

            <div class="form-group mt-2 float-right">
              <b-button variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="storeRikmatek">
                Simpan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-form"
            :title="title_modal"
            centered
            hide-footer
          >
            <div v-if="data_item.length != 0" class="form-group">
              <label>Pilih Pemberi Persetujuan</label>
              <v-select
                v-model="ajukan"
                label="name"
                placeholder="Pilih Pemberi Persetujuan"
                :options="data_ajukan"
                required="required"
              />
            </div>
            <p v-else>
              Daftar item barang kosong, silahkan diisi dulu untuk melakukan pengajuan.
            </p>
            <div class="form-group mt-2 float-right">
              <b-button class variant="info mr-1" @click="hideModal"> Batal </b-button>
              <b-button
                v-if="data_item.length != 0"
                variant="primary"
                @click.prevent="submitAjukan"
              >
                Ajukan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-revisi"
            :title="title_modal"
            centered
            hide-footer
          >
            <!-- <div class="form-group">
              <label>Alasan Revisi</label>
              <textarea
                v-model="note_return"
                rules="required"
                placeholder="Alasan Revisi"
              />
            </div> -->
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Alasan Revisi</label>
              </div>
              <div class="col-md-9">
                <textarea
                  v-model="note_return"
                  class="form-control"
                  placeholder="Alasan Revisi"
                  rules="required"
                />
              </div>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitRevisi">
                Revisi
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-terima"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menerima SPB ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitTerima">
                Terima SPB
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-setuju"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menyetujui SPB ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitSetuju">
                Setujui SPB
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-kirim"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda yakin ingin mengajukan rikmatek ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitKirim">
                Pengajuan Rikmatek
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-prepare"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda yakin, jika data SPB ini telah lengkap ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitPrepare">
                Lengkap
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-batalkan"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin membatalkan SPB ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Kembali </b-button>
              <b-button variant="primary" @click.prevent="submitBatalkan">
                Batalkan SPB
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-send_letter"
            :title="title_modal"
            centered
            hide-footer
          >
            <!-- <div class="form-group">
              <label for="">Rencana Tanggal Pengiriman</label>
              <input
                v-model="dataSend"
                type="date"
                class="form-control"
                placeholder="Rencana Tanggal Pengiriman"
                required
              >
            </div> -->
            <!-- <b-form-group label-cols-md="3" label="Tanggal">
              <flat-pickr
                v-model="plan_received_date"
                class="form-control"
                placeholder="Rencana Tanggal Pengiriman"
              />
            </b-form-group> -->
            <b-table
              v-if="vehicle"
              striped
              responsive
              :items="vehicle"
              :fields="fieldVehiclePlan"
            >
              <template #cell(plan_received_date)="data">
                <flat-pickr
                  v-model="data.item.plan_received_date"
                  class="form-control"
                  placeholder="Tanggal Pengiriman"
                />
              </template>
            </b-table>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitSendLetter">
                Kirim
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-distribute"
            :title="title_modal"
            size="lg"
            centered
            hide-footer
          >
            <!-- <div class="form-group">
              <p>Tanggal Rencana Pengiriman {{model.plan_received_date}}</p>
            </div>
            <b-form-group label-cols-md="3" label="Tanggal Penerimaan">
              <flat-pickr
                v-model="received_date"
                class="form-control"
                placeholder="Tanggal Penerimaan"
              />
            </b-form-group> -->
            <b-table
              v-if="vehicle"
              striped
              responsive
              :items="vehicle"
              :fields="fieldVehicle"
            >
              <template #cell(received_date)="data">
                <flat-pickr
                  v-model="data.item.received_date"
                  class="form-control"
                  placeholder="Tanggal Penerimaan"
                />
              </template>
            </b-table>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitDistribute('receive')">
                Terima
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-distribute-non-spb"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Tanggal Pengiriman {{model.plan_received_date}}</p>
            </div>
            <b-form-group label-cols-md="3" label="Tanggal Penerimaan">
              <flat-pickr
                v-model="received_date"
                class="form-control"
                placeholder="Tanggal Penerimaan"
              />
            </b-form-group>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitDistribute('receive/non_spb')">
                Terima
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-distribute-spb"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menerima unit sudah sesuai spb ini dari vendor ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitDistribute('receive/spb')">
                Terima
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-note"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>{{model.note_return}}</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Tutup </b-button>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <h4 class="mb-2"><feather-icon icon="ListIcon" /> Proses</h4>
          <view-time-line :fields="data_time_line" />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card v-if="renderComp">
          <b-tabs>
            <b-tab active>
              <template v-slot:title>
                <feather-icon icon="HomeIcon" />
                Detail Item Barang
              </template>
              <item ref="item" :posturl="posturl" @showData="showItem" />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <feather-icon icon="FileIcon" />
                Dokumen
              </template>

              <document ref="document" :posturl="posturl" />
            </b-tab>
            <b-tab v-if="variantItem">
              <template v-slot:title>
                <feather-icon icon="FolderIcon" />
                Daftar Kendaraan
              </template>
              <vehicle @showData="showVehicle" ref="vehicle" :posturl="posturl" :variant="variantItem" />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <feather-icon icon="FileIcon" />
                Daftar Denda
              </template>

              <penalty :posturl="posturl" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BTabs,
  BTab,
  BFormGroup,
  BTable,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BDropdownDivider
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import Document from './document/Index.vue'
import Item from './item/Index.vue'
import Vehicle from './vehicle/Index.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import vSelect from 'vue-select'
import Penalty from './penalty/Index.vue'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    FormLabel,
    ViewTimeLine,
    BTabs,
    BTab,
    Document,
    Item,
    Vehicle,
    flatPickr,
    BFormGroup,
    FormInput,
    BTable,
    BFormCheckbox,
    vSelect,
    Penalty
  },
  data() {
    return {
      userType:null,
      user:null,
      userId:null,
      role:null,
      loading: false,
      model: {},
      baseroute: 'spb',
      posturl: '/spb',
      tombol_hide: true,
      ajukan: '',
      note_return:null,
      renderComp: true,
      title_modal: null,
      dataSend: null,
      data_ajukan: [],
      data_item: {},
      data_barang: {},
      data_time_line: [],
      variantItem: null,
      rikmatek:{
        variant:null,
        details:[]
      },
      status_spb:{
        created:{color:'bg-info', value:'Pembuatan SPB'},
        applied:{color:'bg-info', value:'Pengajuan SPB'},
        viewed:{color:'bg-success', value:'SPB Ditinjau'},
        approved:{color:'bg-success', value:'SPB Disetujui'},
        prepared:{color:'bg-success', value:'Penyiapan Unit Oleh Vendor'},
        delivered:{color:'bg-info', value:'Pengiriman SPB Ke Vendor'},
        received:{color:'bg-success', value:'Penerimaan Unit'},
        distributed:{color:'bg-info', value:'Pengiriman Unit dari Vendor'},
        returned:{color:'bg-warning', value:'SPB Direvisi'},
        canceled:{color:'bg-danger', value:'Dibatalkan'},
        request_rikmatek:{color:'bg-success', value:'Pengajuan Rikmatek'},
        checked:{color:'bg-success', value:'Sudah Diperiksa'},
        send_letter_distribute:{color:'bg-success', value:'Pengiriman Unit'},
        bapp_requested:{color:'bg-success', value:'Pengajuan BAPP'},
        bapp_published:{color:'bg-success', value:'BAPP Terbit'},
        undefined:{color:'bg-secondary', value:''},
      },
      plan_received_date:null,
      received_date:moment().format("YYYY-MM-DD"),
      loadingButton: false,
      vehicle:[],
      fieldVehicle:[
        {key:"number_plate", label:"Nopol"},
        {key:"plan_received_date", label:"Tanggal Pengiriman"},
        {key:"received_date", label:"Tanggal Penerimaan"},
      ],
      fieldVehiclePlan:[
        {key:"number_plate", label:"Nopol"},
        {key:"plan_received_date", label:"Tanggal Pengiriman"},
      ],
      fieldRikItem:[
        {key:"model", label:"Item"},
        {key:"item_check", label:"Item Pengujian"},
        {key:"result", label:"Hasil"},
        {key:"result_check", label:"Hasil Pemeriksaan"},
        {key:"note", label:"Keterangan"},
        "_"
      ],
      periodePayments: [],
      setReceive:false,
      setPlanReceive:false,
      item_check:null,
      arr_item_check:[],
      rikmatekVariant:null,
      result:null,
      arr_result:[],
      isPrintRikmatek:false
    }
  },
  // beforeMount() {
  //   this.$http.get('/staff?length=').then(ref => {
  //     this.data_ajukan = ref.data.data
  //   })
  // },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.getData()
        this.getDataItem()
        this.getDataBarang()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
      }
    },
    model:function(newData){
      this.model.plan_received_date = newData.plan_received_date
    },
    data_item:function(){
      if(this.vehicle)this.setTerima()
    },
    vehicle:function(){
      if(this.data_item)this.setTerima()
    }

  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.userId = this.$store.state.profile.info.id
      this.user = this.$store.state.profile.info.contact
      this.role = this.$store.state.profile.info.role
    }
    if(this.$route.meta.baseroute)
      this.baseroute = this.$route.meta.baseroute;
  },
  mounted() {
    this.getData()
    this.getDataItem()
    this.getDataBarang()
  },
  methods: {
    showVehicle(data){
      this.vehicle = data
      data.forEach(vehicle => {
        if(vehicle.plan_received_date)
          this.model.plan_received_date = vehicle.plan_received_date
      })
    },
    isSpbApprove(settings){
      for (let setting of settings){
        if (setting.approval && setting.module){
          if (setting.module.code == 'spb.approve')
            return true
        }
      }
      return false
    },
    getData() {
      this.$http.get(this.posturl + '/' + this.$route.params.id).then(res => {
        this.model = res.data
        var tracks = res.data.tracks
        this.data_time_line = tracks.filter(function(item) {return item.status !== 'viewed'})

        this.$http.get('/staff?filter[branch_id]='+this.model.branch_id+'&filter[approval_status]=1&length=').then(res => {
          var staffs = res.data.data
          var staff_sel = []
          for (let index = 0; index < staffs.length; index++) {
            var staff = staffs[index];
            if (this.isSpbApprove(staff.job_title.settings)){
              staff_sel.push(staff)
            }
          }
          this.data_ajukan = staff_sel
        })
      })

      this.$http.get(this.posturl + '/' + this.$route.params.id + '/payment').then(res => {
        this.periodePayments = res.data
      })
    },
    getDataItem() {
      this.$http.get(this.posturl + '/' + this.$route.params.id + '/item').then(res => {
        this.data_item = res.data.data
        if(this.data_item.filter(x => x.rikmatek).length == 0)
          this.isPrintRikmatek = false
        else{
          this.isPrintRikmatek = true
        }
      })
    },
    getDataBarang() {
      this.$http
        .get(this.posturl + '/' + this.$route.params.id + '/vehicle')
        .then(res => {
          this.data_barang = res.data.data
        })
    },
    editData(data) {
      this.$router.push({ name: this.baseroute + '-edit', params: { id: data.id } })
    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm(
          data.contract_subject != null
            ? 'Anda Ingin Menghapus Data ' +
                data.contract_subject +
                ' ini ? Karena akan berhubungan dengan data lain ?'
            : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?',
          {
            title: 'Please Confirm',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
          }
        )
        .then(confirm => {
          if (confirm) {
            this.$http
              .delete(this.posturl + '/' + data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })
              })
              .catch(err => {
                this.errorSubmit(err)
              })
          }
        })
    },
    showPersiapanUnit(){
      this.$router.push({ name: 'persiapan-unit-vendor-show', params : {id:this.$route.params.id} })
    },
    showNote(){
      this.title_modal = 'Info'
      this.$refs['my-modal-note'].show()
    },
    showKirim() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-kirim'].show()
    },
    showPrepare() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-prepare'].show()
    },
    showSetuju() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-setuju'].show()
    },
    showTerima() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-terima'].show()
    },
    showRikmatek() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-rikmatek'].show()
    },
    showDelivered() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-delivered'].show()
    },
    showReceive() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-send_letter'].show()
    },
    showDistribute() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-distribute'].show()
    },
    showDistributeNonSpb() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-distribute-non-spb'].show()
    },
    showDistributeSpb() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-distribute-spb'].show()
    },
    showAjukan() {
      this.getDataItem()
      this.title_modal = 'Pengajuan SPB'
      this.$refs['my-modal-form'].show()
    },
    showBatalkan() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-batalkan'].show()
    },
    showRevisi() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-revisi'].show()
    },
    submitAjukan() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/apply', {pic_id : this.ajukan.id})
        .then(() => {
          this.ajukan = ''
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-form'].hide()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          })
          this.$store.dispatch('sendMessage', '1')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pengajuan SPB sukses',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitBatalkan() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/cancel').then(() => {
        this.getData()
        this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-batalkan'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil dibatalkan',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitSendLetter() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/deliver', {vehicle:this.vehicle})
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-send_letter'].hide()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Surat Pengiriman Berhasil dibuat',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitDelivered() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/distribute')
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-delivered'].hide()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rikmatek berhasil diajukan',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    showToastError(msg){
      return this.$bvToast.toast(msg ? msg : 'Error', {
            title: 'Error',
            solid: true,
            variant: 'danger',
          })
    },
    async submitRikmatek() {
      this.loading = true
      let print = await this.$http.get('spb/'+this.$route.params.id+'/rikmatek/print')
      if(print){
        if(this.model.status == 'request_rikmatek'){
          this.$http.put(this.posturl + '/' + this.$route.params.id + '/check').then(() => {
            this.getData()
            // this.$refs['my-modal-rikmatek'].hide()
            this.renderComp = false
            this.$nextTick().then(() => {
              this.renderComp = true
            })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pengajuan Kendaraan telah lengkap',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            window.open(print.data.filename)
          })
        }else{
          window.open(print.data.filename)
        }
      }
      this.loading = false
    },
    async printSPB() {
      if (this.data_item.length > 0){
        this.loading = true
        await this.$http.get('spb/'+this.$route.params.id+'/spb/print').then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Dokumen SPB telah dicetak',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          window.open(res.data.filename)
        })
        .catch( err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Terjadi Kesalahan',
              solid: true,
              variant: 'error',
            },
          })
        })
        .finally(() => { this.loading = false })
      }
      else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB tidak dapat dicetak, Tidak Ada Item Barang',
            solid: true,
            variant: 'danger',
          },
        })
      }
        
    },
    storeRikmatek(){
      if(this.rikmatek.details.length == 0)
        return this.showToastError('Item tidak boleh kosong')
      if(this.rikmatekVariant.length != 0)
        return this.showToastError('Item ada yang belum di set')

      this.$http.post(this.posturl + '/' + this.$route.params.id + '/rikmatek', this.rikmatek).then(() => {
        this.getData()
        this.$refs['my-modal-rikmatek'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rikmatek berhasil disimpan',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitRevisi() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/return', {note_return:this.note_return}).then(() => {
        this.getData()
        this.$refs['my-modal-revisi'].hide()
        this.note_return = null
        this.$store.dispatch('sendMessage', '1')
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Revisi berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitKirim() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/rikmatek')
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-kirim'].hide()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Penyiapan unit berhasil',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitTerima() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/receive').then(() => {
        this.getData()
        this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-terima'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil diterima',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitPrepare() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/prepare')
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-prepare'].hide()
          this.renderComp = false
          this.$nextTick().then(() => {
            this.renderComp = true
          })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'SPB telah lengkap',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          const msg = err.response.data.message
          this.$bvToast.toast(msg ? msg : 'Submit error', {
            title: 'Error',
            solid: true,
            variant: 'danger',
          })
        })
    },
    submitSetuju() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/approve').then(() => {
        this.getData()
        this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-setuju'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'SPB berhasil disetujui',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitDistribute(receive) {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/'+receive, {vehicle:this.vehicle}).then(() => {
        this.getData()
        this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-distribute'].hide()
        this.$refs['my-modal-distribute-non-spb'].hide()
        this.$refs['my-modal-distribute-spb'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Penerimaan unit berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    hideModal() {
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$refs['my-modal-distribute'].hide()
      this.$refs['my-modal-distribute-non-spb'].hide()
      this.$refs['my-modal-distribute-spb'].hide()
      this.$refs['my-modal-rikmatek'].hide()
      this.$refs['my-modal-delivered'].hide()
      this.$refs['my-modal-send_letter'].hide()
      this.$refs['my-modal-note'].hide()
    },
    cancel() {
      this.$router.push({ name: this.$route.meta.pageActive })
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$refs['my-modal-form'].hide()
      this.$refs['my-modal-batalkan'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-kirim'].hide()
      this.$refs['my-modal-prepare'].hide()
      this.$refs['my-modal-terima'].hide()
      this.$refs['my-modal-rikmatek'].hide()
      this.$refs['my-modal-delivered'].hide()
      this.$refs['my-modal-send_letter'].hide()
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    showItem(data) {
      this.getDataItem()
      const variant = []
      const rikVariant = []
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          rikVariant.push({
            id: data[i].id,
            variant_id: data[i].variant_id,
            model: data[i].variant.model
          })
          variant.push({
            id: data[i].variant_id,
            model: data[i].variant.type ? data[i].variant.type.name+' - '+data[i].variant.manufacture.name+' - '+data[i].variant.model+' - '+data[i].description : 
                    data[i].variant.manufacture ? data[i].variant.manufacture.name+' - '+data[i].variant.model :
                        data[i].variant.model,
            qty:data[i].qty?data[i].qty:0
          })
        }
      }
      this.variantItem = variant
      if(data.filter(x => x.rikmatek).length == 0)
        this.rikmatekVariant = rikVariant
      else{
        this.rikmatekVariant = null
      }
    },
    printRikmatek(){
      this.$http.get('spb/'+this.$route.params.id+'/rikmatek/print').then(res=>{
        window.open(res.data.filename)
      })
    },
    printMaintenanceDoc(data){
      this.loading = true;
      let params = {
        periode: data
      }
      this.$http.post('spb/'+this.$route.params.id+'/maintenance/print', params).then(res=>{
        this.loading = false;
        window.open(res.data.filename)
      })
    },
    setTerima(){
      this.setReceive = false
      this.setPlanReceive = false
      let jml_vehicle_plan = this.vehicle.filter(x => x.plan_received_date).length
      let jml_vehicle_receive = this.vehicle.filter(x => x.received_date).length
      let jml_item = 0;
      this.data_item.forEach(x => {
        jml_item += parseInt(x.qty)
      })
      if(jml_item > jml_vehicle_receive){
        if(['bapp_requested','bapp_published','received','distributed'].includes(this.model.status))
          this.setReceive = true
      }
      if(jml_item > jml_vehicle_plan){
        if(['send_letter_distribute','bapp_requested','bapp_published','received','distributed'].includes(this.model.status))
          this.setPlanReceive = true
      }
    },
    addItemCheck(){
      if(this.item_check){
        this.arr_item_check.push(this.item_check)
      }
      this.item_check = ''
    },
    rmItemCheck(index){
      this.arr_item_check.splice(index, 1)
    },
    addResult(){
      if(this.result){
        this.arr_result.push(this.result)
      }
      this.result = ''
    },
    rmResult(index){
      this.arr_result.splice(index, 1)
    },
    rmDetail(data){
      this.rikmatekVariant.push(data.item.item)
      this.rikmatek.details.splice(data.index, 1)
    },
    addDetailRikmatek(){
      if(!this.rikmatek.variant)
        return this.showError('Item is empty')
      if(this.arr_item_check.length == 0)
        return this.showError('Item Pengujian is empty')
      if(this.arr_result.length == 0)
        return this.showError('Hasil is empty')

      var item_check = ""
      this.arr_item_check.forEach((x, index) => {
        if(index>0){
          item_check += " | "
        }
        item_check += x
      })

      var result = ""
      this.arr_result.forEach((x, index) => {
        if(index>0){
          result += " | "
        }
        result += x
      })

      this.rikmatek.details.push({
        item:this.rikmatek.variant,
        spb_id:this.$route.params.id,
        item_id:this.rikmatek.variant.id,
        model:this.rikmatek.variant.model,
        item_check:item_check,
        result:result,
        result_check:this.rikmatek.result_check,
        note:this.rikmatek.note?this.rikmatek.note:''
      })

      this.rikmatekVariant = this.rikmatekVariant.filter(x => x.id != this.rikmatek.variant.id)
      this.rikmatek.variant = null
      this.arr_item_check = []
      this.arr_result = []
      this.rikmatek.result_check = null
      this.rikmatek.note = null
    },
    splitItem(result){
      var value = ""
      if(result.includes(" | ")){
        var arr_result = result.split(" | ")
        arr_result.forEach((x, index) => {
          value += index+1+". "+x+" "
        })
      }else{
        value = result
      }
      return value
    },
    showError(msg){
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    changeItem(data){
      if(data){
        var unit = this.vehicle.filter(x => x.variant_id == data.variant_id).length
        this.rikmatek.result_check = 'Sejumlah : '+unit+' unit Dengan spesifikasi SESUAI kontrak'
      }else{
        this.rikmatek.result_check = null
      }
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.dropdown-scroll /deep/ .dropdown-menu {
    max-height: 340px;
    overflow-y: auto;
}
</style>
