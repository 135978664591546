<template>
  <app-timeline>
    <app-timeline-item
      v-for="data in fields"
      :key="data.id"
      :title="synStatus(data.status)"
      :subtitle="tanggal(data.created_at)+', oleh '+data.author.name"
      :variant="randomColor(warna)"
    />
  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from "moment"

  export default {
    components: {
      AppTimeline,
      AppTimelineItem,
    },
    props:{
      fields: {
        type: Array,
        default() {
          return []
        },
      }
    },
    data() {
      return {
        warna:['info','danger','success','warning']
      }
    },
    methods:{
      randomColor(item){
        return item[Math.floor(Math.random()*item.length)]
      },
      tanggal(date){
        return moment(date).local('id').format('DD-MMMM-YYYY')
      },
      synStatus(text){
        if(text == 'canceled'){
          var data = 'Dibatalkan'
        }else if(text == 'viewed'){
          var data = 'SPB Ditinjau'
        }else if(text == 'created'){
          var data = 'Pembuatan SPB'
        }else if(text == 'applied'){
          var data = 'Pengajuan SPB'
        }else if(text == 'delivered'){
          var data = 'Pengiriman SPB Ke Vendor'
        }else if(text == 'prepared'){
          var data = 'Penyiapan Unit Oleh Vendor'
        }else if(text == 'received'){
          var data = 'Pengiriman Unit'
        }else if(text == 'approved'){
          var data = 'SPB Disetujui'
        }else if(text == 'distributed'){
          var data = 'Pengiriman Unit Ke Vendor'
        }else if(text == 'returned'){
          var data = 'SPB Direvisi'
        }else if(text == 'request_rikmatek'){
          var data = 'Pengajuan Rikmatek'
        }else if(text == 'send_letter_distribute'){
          var data = 'Sudah Pemeriksaan'
        }else if(text == 'bapp_published'){
          var data = 'BAPP Dipublish'
        }else if(text == 'bapp_requested'){
          var data = 'Sudah Direquest'
        }else if(text == 'checked'){
          var data = 'Sudah Diperiksa'
        }else{
          var data = '-'
        }
        return data
      }
    }
    // created(){
    //   this.
    // }
  }
</script>

<style lang="scss" scoped>

</style>