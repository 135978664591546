<template lang="">
  <div v-if="showTable">
    <basetable
      v-if="renderComp"
      :dataurl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :title="title"
      :tombol_hide="tombol_hide"
      :search_hide="search_hide"
      :page_size_hide="true"
      :no_margin="true"
      @showData="showData"
    >          
      <template v-slot:buttonaddon>
        <b-button
          v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? false : true"
          class="mb-1"
          variant="success"
          type="button"
          @click.prevent="tampilForm()"
        >
          Tambah Item Barang {{ item_id }}
        </b-button>
        <span v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? true : false" />
      </template>
      <template #cell(variant.model)="data">
        {{ data.item.variant.type.name + ' - ' + data.item.variant.manufacture.name + ' - ' + data.item.variant.model }}
      </template>
      <template #cell(duration)="data">
        {{ data.item.duration }}
        <span v-if="data.item.duration_unit == 'day'">Hari</span>
        <span v-else-if="data.item.duration_unit == 'month'">Bulan</span>
        <span v-else>Tahun</span>
      </template>
      <template #cell(price)="data">
        {{ 'Rp'+formatPrice(data.item.price) }}
      </template>
      <template #cell(price_maintenance)="data">
        {{ 'Rp '+formatPrice(data.item.price_maintenance) }}
      </template>
      <template #cell(is_tax)="data">
        {{ data.item.is_tax ? 'Include' : 'Exclude' }}
      </template>
      <template #cell(action)="data">
        <b-button
          v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? false : true"
          variant="primary"
          size="sm"
          class="mr-1"
          @click="editForm(data)"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-button>
        <span v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? true : false">&nbsp;</span>
        <b-button
          v-show="(model.status == 'applied' || model.status == 'viewed' || model.status == 'prepared' || model.status == 'delivered' || model.status == 'received' || model.status == 'distributed') ? false : true"
          variant="warning"
          size="sm"
          class="mr-0"
          @click="confirmDelete(data)"
        >
          <feather-icon
            icon="TrashIcon"
          />
        </b-button>
      </template>
    </basetable>
  </div>
  <div
    v-else
    class="m-1 mt-3"
  >
    <h3 class="mb-2">
      {{ form_title }}
    </h3>
    <form>
      <div class="form-group">
        <div class="row">
          <div class="col-md-3">
            <label for="">Jenis Varian</label>
          </div>
          <div class="col-md-5">
            <!-- <template>
              <baseSelectSingle
                :id="'variant'"
                v-model="fields_form.variant"
                :dataurl="'/vehicle-variant'"
                :title="'Jenis Varian'"
              />
            </template> -->
            <!-- <form-v-select
              ref="variant"
              v-model="fields_form.variant"
              :dataurl="'/vehicle-variant'"
              label=""
              rules="required"
              placeholder="Select Jenis Varian"
              :item_text="'model'"
            /> -->
            <v-select
              v-model="fields_form.variant"
              label="model"
              placeholder="Select Jenis Varian"
              :options="variantItem"
              required="required"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Uraian</label>
          </div>
          <div class="col-md-9">
            <input
              v-model="fields_form.description"
              type="text"
              placeholder="Uraian"
              class="form-control"
              required=""
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">TOD</label>
          </div>
          <div class="col-md-3">
            <input
              v-model="fields_form.tod"
              type="number"
              placeholder="TOD"
              min="1"
              class="form-control"
              required=""
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Jumlah Item</label>
          </div>
          <div class="col-md-3">
            <input
              v-model="fields_form.qty"
              type="number"
              placeholder="Jumlah Item"
              min="1"
              class="form-control"
              required=""
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Lama Sewa</label>
          </div>
          <div class="col-md-3">
            <input
              v-model="fields_form.duration"
              type="number"
              placeholder="Lama Sewa"
              min="1"
              class="form-control"
              required=""
            >
          </div>
          <div class="col-md-4">
            <select
              v-model="fields_form.duration_unit"
              class="form-control"
              required=""
            >
              <!-- <option value="day">
                Hari
              </option> -->
              <option value="month">
                Bulan
              </option>
              <option value="year">
                Tahun
              </option>
            </select>
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Type Pembayaran</label>
          </div>
          <div class="col-md-4">
            <select
              v-model="fields_form.due_type"
              class="form-control"
              required=""
            >
              <option value="day">
                Per hari
              </option>
              <option value="month">
                Per tanggal
              </option>
              <option value="all">
                Kelesuruhan
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <input
              v-if="fields_form.due_type == 'day'"
              v-model="fields_form.due_duration"
              type="number"
              placeholder="Durasi"
              min="1"
              class="form-control"
              required="required"
            >
            <flat-pickr
              v-if="fields_form.due_type == 'month'"
              v-model="fields_form.due_date"
              class="form-control"
              placeholder="Tanggal Jatuh Tempo"
              required="required"
            />
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Harga Sewa (Ex. PPN)</label>
          </div>
          <div class="col-md-6">
            <!-- <input
              v-model="fields_form.price"
              type="number"
              placeholder="Harga Satuan (Ex. PPN)"
              min="0"
              class="form-control"
              required=""
            > -->
            <cleave
                v-model="fields_form.price"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="Harga Satuan (Ex. PPN)"
                required=""
              />
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Pajak</label>
          </div>
          <div class="col-md-5">
            <select
              v-model="fields_form.is_tax"
              class="form-control"
              required=""
            >
              <option :value="true">
                Termasuk Pajak
              </option>
              <option :value="false">
                Belum Termasuk Pajak
              </option>
            </select>
          </div>
        </div> -->
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">PPN</label>
          </div>
          <div class="col-md-5">
            <input
              v-model="priceTax"
              type="input"
              placeholder="Pajak"
              min="0"
              class="form-control"
              disabled="true"
            >
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Harga Satuan Pemeliharaan</label>
          </div>
          <div class="col-md-6">
            <!-- <input
              v-model="fields_form.price_maintenance"
              type="number"
              placeholder="Harga Satuan Pemeliharaan"
              min="0"
              class="form-control"
              required=""
            > -->
            <cleave
              v-model="fields_form.price_maintenance"
              class="form-control"
              :raw="true"
              :options="options.number"
              placeholder="Harga Satuan Pemeliharaan"
              required=""
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Uraian Pemeliharaan</label>
          </div>
          <div class="col-md-9">
            <input
              v-model="fields_form.description_maintenance"
              type="text"
              placeholder="Uraian Pemeliharaan"
              class="form-control"
              required=""
            >
          </div>
        </div>
        <!-- <div class="row mt-2">
          <div class="col-md-3">
            <label for="">Keterangan</label>
          </div>
          <div class="col-md-9">
            <textarea
              v-model="fields_form.note"
              class="form-control"
              placeholder="Keterangan"
              required=""
            />
          </div>
        </div> -->
      </div>
      <b-button
        class="mr-1"
        size="md"
        variant="primary"
        type="submit"
        @click.prevent="btnSimpan()"
      >
        <span>Simpan</span>
      </b-button>
      <b-button
        class="mr-1"
        size="md"
        variant="outline-secondary"
        type="button"
        @click.prevent="tampilTable()"
      >
        <span>Batal</span>
      </b-button>
    </form>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleDataV2.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import Cleave from 'vue-cleave-component'

import {
  BCard,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    baseSelectSingle,
    BButton,
    BCard,
    FormVSelect,
    flatPickr,
    vSelect,
    Cleave
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dataurl: this.posturl+"/"+this.$route.params.id+"/item",
      baseroute:"spb",
      title:"",
      form_title:null,
      tombol_hide:true,
      search_hide: true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'variant.model', label: 'Nama Barang', sortable: true },
        { key: 'description', label: 'Uraian', sortable: true },
        { key: 'tod', label: 'TOD', sortable: true },
        // { key: 'variant.code', label: 'Merk', sortable: true },
        { key: 'qty', label: 'Jumlah', sortable: true },
        { key: 'duration', label: 'Lama Sewa', sortable: true },
        { key: 'price', label: 'Harga Sewa', sortable: true },
        { key: 'is_tax', label: 'PPn', sortable: true },
        { key: 'description_maintenance', label: 'Uraian Pemeliharaan', sortable: true },
        { key: 'price_maintenance', label: 'Biaya Pemeliharaan', sortable: true },
      ],
      fields_form :{
        variant:null,
        variant_id:null,
        description:null,
        tod:1,
        qty:1,
        duration:100,
        duration_unit:'month',
        price:null,
        is_tax:false,
        note:null,
        due_type:'month',
        due_date:moment().format('YYYY-MM-DD'),
        due_duration:null,
        price_maintenance:null,
        description_maintenance:null,
      },
      model:{}, 
      variantItem: [],
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      tableData: [],
    }
  },
  computed:{
    priceTax: function() {
      return 'Rp '+this.formatPrice(this.fields_form.price * 0.1);
    }

  },
  mounted(){
    this.getData()
  },
  methods:{
    formatPrice(value) {
        let val = (value/1).toFixed(0).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    showData(data){
      this.$emit('showData', data)
      this.tableData = data;
      this.getData();
    },
    checkVariantItem(variant){
      for (let var_item of this.tableData){
        if (var_item.variant_id == variant.id){
          return true;
        }
      }
      return false;
    },
    getData(){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        this.model = res.data
      })
      this.$http.get('/vehicle-variant?length=').then(res=>{
        const variant = []
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          let model_name = data[i].type.name + ' - ' + data[i].manufacture.name + ' - ' + data[i].model;
          if (!this.checkVariantItem(data[i])){
            variant.push({
              id: data[i].id,
              model: model_name,
            })
          }
        }
        this.variantItem = variant
      })
     },
    tampilForm(){
      this.showTable = false
      this.form_title = "Tambah Item Barang"
      this.valid_form = 'tambah'
      this.resetForm()
    },
    tampilTable(){
      this.showTable = true
    },
    btnSimpan(){
      var message = ''
      if(!this.fields_form.variant){
        message = 'The variant field is required'
      }else if(!this.fields_form.tod){
        message = 'The TOD field is required'
      }else if(!this.fields_form.qty){
        message = 'The QTY field is required'
      }else if(!this.fields_form.duration){
        message = 'The Duration field is required'
      }else if(!this.fields_form.price){
        message = 'The Price field is required'
      }else if(!this.fields_form.description){
        message = 'The Description field is required'
      }else if(!this.fields_form.price_maintenance){
        message = 'The Price Maintenance field is required'
      }else if(!this.fields_form.description_maintenance){
        message = 'The Description Maintenance field is required'
      }
      // else if(!this.fields_form.note){
      //   message = 'The Note field is required'
      // }else if(this.fields_form.due_type == 'month' && !this.fields_form.due_date){
      //   message = 'The Due Date field is required'
      // }else if(this.fields_form.due_type == 'day' && !this.fields_form.due_duration){
      //   message = 'The Duration Day field is required'
      // }
      
      if(message != '')
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Form Error',
            text: message,
            icon: 'CheckCircleIcon',
            variant: 'danger',
          },
        })
        
      this.filterDataSubmit()
      if(this.valid_form == 'tambah'){
        this.$http.post('/spb/'+this.$route.params.id+'/item',this.fields_form).then(()=>{
        this.resetForm()
          this.showTable = true
        })
      }else{
        this.$http.put('/spb/'+this.$route.params.id+'/item/'+this.id_item,this.fields_form).then(()=>{
          this.resetForm()
          this.showTable = true
        })
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    editForm(data){
      this.fields_form.variant = data.item.variant
      this.fields_form.description = data.item.description
      this.fields_form.tod = data.item.tod
      this.fields_form.qty = data.item.qty
      this.fields_form.duration = data.item.duration
      this.fields_form.duration_unit = data.item.duration_unit
      this.fields_form.price = data.item.price
      this.fields_form.is_tax = data.item.is_tax
      this.fields_form.note = data.item.note
      this.fields_form.due_type = data.item.due_type
      this.fields_form.due_date = data.item.due_date
      this.fields_form.due_duration = data.item.due_duration
      this.fields_form.price_maintenance = data.item.price_maintenance
      this.fields_form.description_maintenance = data.item.description_maintenance
      this.showTable = false
      this.form_title = "Edit Item Barang"
      this.valid_form = 'edit'
      this.id_item = data.item.id
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm(data.item.variant != null ? 'Anda Ingin Menghapus Data '+data.item.variant.model +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                })
            })
          }
        })
    },
    resetForm(){
      this.fields_form = {
        variant:null,
        variant_id:null,
        description:null,
        tod:1,
        qty:1,
        duration:0,
        duration_unit:'month',
        price:null,
        is_tax:false,
        note:'',
        due_type:'month',
        due_duration:null,
        due_date:null,
        price_maintenance:null,
        description_maintenance:null
      }
    },
    async filterDataSubmit() {
      let newData ={}
      // console.log(this.model)
        if(typeof this.fields_form.variant === 'object'){
          if(Array.isArray(this.fields_form.variant)){
            let arrData = this.fields_form.variant.map(e => e.id)
            newData[this.fields_form.variant] = arrData
          }else{
            newData['variant_id'] = this.fields_form.variant.id
            this.fields_form.variant_id = this.fields_form.variant.id
          }
        }
    }
  }
}
</script>
<style lang="">

</style>
