<template lang="">
  <div v-if="showTable">
    <basetable
      ref="basetable"
      v-if="renderComp"
      :dataurl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      :title="title"
      :tombol_hide="tombol_hide"
      :search_hide="true"
      :page_size_hide="true"
      :no_margin="true"
      @showData="showVehicle"
    >
      <template v-slot:buttonaddon>
        <b-button
          class="mb-1"
          variant="success"
          type="button"
          @click.prevent="tampilForm()"
        >
          Tambah Item Barang 
        </b-button>
      </template>
      <!-- <template #cell(duration)="data">
        {{ data.item.duration }}
        <span v-if="data.item.duration_unit == 'day'">Hari</span>
        <span v-else-if="data.item.duration_unit == 'month'">Bulan</span>
        <span v-else>Tahun</span>
      </template> -->
      <template #cell(action)="data">
        <div>
          <b-button
            v-if="data.item.temporary == 'vehicle' || data.item.temporary == 'number_plate'"
            variant="info"
            size="sm"
            class="mr-1"
            title="Change"
            @click="changeTemporaryVehicle(data)"
          >
            <feather-icon
              icon="MoveIcon"
            />
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="editForm(data)"
          >
            <feather-icon
              icon="EditIcon"
            />
          </b-button>
          <b-button
            variant="warning"
            size="sm"
            class="mr-1"
            @click="confirmDelete(data)"
          >
            <feather-icon
              icon="TrashIcon"
            />
          </b-button>
          <b-button
            v-if="data.item.spb.status == 'bapp_published'"
            variant="success"
            size="sm"
            class="mr-1"
            @click="showDistribute(data)"
          >
            <feather-icon
              icon="SendIcon"
            />
          </b-button>
        </div>
      </template>
      <template #cell(variant.model)="data">
        {{ data.item.variant.type.name + ' - ' + data.item.variant.manufacture.name + ' - ' + data.item.variant.model }}
      </template>
      <template #cell(temporary)="data">
        {{ data.item.temporary == 'vehicle' ? "Kendaraan Sementara" : data.item.temporary == 'number_plate' ? "PLAT NOMOR Sementara" : "Sesuai" }} {{ data.item.temporary == 'vehicle' ? (" - " + data.item.temporary_variant.type.name + ' - ' + data.item.temporary_variant.manufacture.name + ' - ' + data.item.temporary_variant.model) : " " }}
      </template>
      <template #cell(distributed_area)="data">
        {{ !data.item.distributed_location ? '-' : 
            data.item.branch.level == 2 ? data.item.branch.name :
                data.item.branch.level == 3 ? data.item.branch.parent.name :
                    data.item.branch.level == 4 ? data.item.branch.parent.parent.name : "-"
        }}
      </template>
      <template #cell(distributed_ulp)="data">
        {{ !data.item.distributed_location ? '-' : 
            data.item.branch.level == 3 ? data.item.branch.name :
                data.item.branch.level == 4 ? data.item.branch.parent.name : "-"
        }}
      </template>
      <template #cell(distributed_kantorjaga)="data">
        {{ !data.item.distributed_location ? '-' : 
            data.item.branch.level == 4 ? data.item.branch.name : '-' 
        }}
      </template>
      <template #cell(distributed_project)="data">
        {{ data.item.project ? data.item.project.name : '-' }}
      </template>
    </basetable>
    <b-modal
      id="modal-center"
      ref="my-modal-distribute"
      :title="'Distribusikan Kendaraan'"
      centered
      hide-footer
    >
      <b-form-group
          label-cols-md="3"
          label="Area"
        >
        <v-select
          v-model="area"
          label="name"
          placeholder="Pilih Area"
          :options="opArea"
          @input="changeSelectArea"
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label="ULP"
        >
        <v-select
          v-model="ulp"
          label="name"
          placeholder="Pilih ULP"
          :options="opUlp"
          @input="changeSelectUlp"
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label="Kantor Jaga"
        >
        <v-select
          v-model="kantorjaga"
          label="name"
          placeholder="Pilih Kantor Jaga"
          :options="opKantorJaga"
        />
      </b-form-group>
      <b-form-group
          label-cols-md="3"
          label="Project"
        >
        <v-select
          v-model="project"
          label="full_name"
          placeholder="Pilih Project"
          :options="opProject"
        />
      </b-form-group>

      <b-form-group
          label-cols-md="3"
          label="Digunakan Oleh"
        >
        <v-select
          v-model="staff"
          label="name"
          placeholder="Pilih Staff"
          :options="opStaff"
        />
      </b-form-group>

      <div class="form-group mt-2 float-right">
        <b-button
          class
          variant="primary mr-1"
          @click="hideModal"
        >
          Batal
        </b-button>
        <b-button
          variant="warning"
          @click.prevent="submitDistribute"
        >
          Kirim
        </b-button>
      </div>
    </b-modal>
  </div>
  <div
    v-else
    class="m-1 mt-3"
  >
    <h3 class="mb-2">
      {{ form_title }}
    </h3>
    <form>
      <div class="form-group">
        <b-row>
          <b-col cols="6">
            <div class="row" v-if="!fields_form.temporary_vehicle_id">
              <div class="col-md-3">
                <label for="">Jenis Varian</label>
              </div>
              <div class="col-md-8">
                <!-- <template>
                  <baseSelectSingle
                    :id="'variant'"
                    v-model="fields_form.variant"
                    :dataurl="'/vehicle-variant'"
                    :title="'Jenis Varian'"
                  />
                </template> -->
                <v-select
                  v-model="fields_form.variant"
                  label="model"
                  placeholder="Pilih Variant"
                  :options="variant"
                  required="required"
                />
              </div>
            </div>
            <div class="row mt-2" v-if="!change_form">
              <div class="col-md-3">
                <label for="">Sementara</label>
              </div>
              <div class="col-md-8">
                  <div class="row">
                    <div class="col-2">
                      <input
                        v-model="is_temporary"
                        type="checkbox"
                        @input="changeTemporary"
                        required=""
                      />
                    </div>
                    <div class="col-10" v-if="is_temporary">
                      <select class="select2 form-select form-control" id="cboTingkat" v-model="fields_form.temporary">
                        <option :value="null">Pilih Varian Sementara</option>
                        <option v-for="rad in [
                            {name: 'Kendaraan', id: 'vehicle'},
                            {name: 'PLAT NOMOR', id: 'number_plate'},
                          ]" :value="rad.id">{{ rad.name }}</option>
                      </select>
                    </div>
                  </div>
                  <br>
                  <p class="badge badge-warning text-wrap text-left">Pilih Jika Ingin Mengirimkan Kendaraan Sementara Atau Plat Nomor Sementara</p>
              </div>
            </div>
            <div class="row mt-2" v-if="fields_form.temporary == 'vehicle'">
              <div class="col-md-3">
                <label for="">Jenis Varian Baru</label>
              </div>
              <div class="col-md-8">
                <v-select
                  v-model="fields_form.temporary_variant"
                  label="model"
                  placeholder="Pilih Variant Baru Sementara"
                  :options="variantItem"
                  required="required"
                />
              </div>
            </div>
            <div class="row mt-2" v-if="fields_form.temporary_vehicle_id">
              <div class="col-md-3">
                <label for="">Jenis Varian</label>
              </div>
              <div class="col-md-8">
                {{ fields_form.variant.model }}
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">NOPOL</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.number_plate"
                  type="text"
                  placeholder="NOPOL"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Tahun</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.year"
                  type="number"
                  placeholder="Tahun"
                  min="1"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Warna</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.color"
                  type="text"
                  placeholder="Warna"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Asuransi</label>
              </div>
              <div class="col-md-8">
                <v-select
                  v-model="fields_form.assurance"
                  label="name"
                  placeholder="Pilih Asuransi"
                  :options="opAsuransi"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Bengkel</label>
              </div>
              <div class="col-md-8">
                <v-select
                  v-model="fields_form.workshop"
                  label="name"
                  placeholder="Pilih Bengkel"
                  :options="opWorkshop"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="row">
              <div class="col-md-3">
                <label for="">Nomor BPKB</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.bpkb_number"
                  type="text"
                  placeholder="Nomor BPKB"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Nomor STNK</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.stnk_number"
                  type="text"
                  placeholder="Nomor STNK"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Nama STNK</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.stnk_name"
                  type="text"
                  placeholder="Nama STNK"
                  min="0"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">Tanggal STNK</label>
              </div>
              <div class="col-md-8">
                <flat-pickr
                  v-model="fields_form.stnk_date"
                  class="form-control"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">No Rangka</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.frame_number"
                  type="text"
                  placeholder="No Rangka"
                  min="0"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-3">
                <label for="">No Mesin</label>
              </div>
              <div class="col-md-8">
                <input
                  v-model="fields_form.machine_number"
                  type="text"
                  placeholder="No Mesin"
                  min="0"
                  class="form-control"
                  required=""
                >
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        class="mr-1"
        size="md"
        variant="primary"
        type="submit"
        @click.prevent="btnSimpan()"
      >
        <span>Simpan</span>
      </b-button>
      <b-button
        class="mr-1"
        size="md"
        variant="outline-secondary"
        type="button"
        @click.prevent="tampilTable()"
      >
        <span>Batal</span>
      </b-button>
    </form>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleDataV2.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moment from 'moment'
import FormRadio from '@/views/base/form/FormRadio.vue'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BModal,
  BFormGroup
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    basetable,
    baseSelectSingle,
    flatPickr,
    BButton,
    BCard,
    BModal,
    vSelect,
    BFormGroup,
    FormRadio,
  },
  props:{
    item_id: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
    variant:{
      type:Array,
      default (){
        return []
      }
    }
  },
  data() {
    return {
      dataurl: this.posturl+"/"+this.$route.params.id+"/vehicle",
      baseroute:"spb",
      title:"",
      form_title:null,
      tombol_hide:true,
      showTable : true,
      renderComp:true,
      valid_form : null,
      id_item:null,
      fields: [
        { key: 'variant.model', label: 'Nama Barang', sortable: true },
        { key: 'temporary', label: 'Keterangan', sortable: true },
        { key: 'number_plate', label: 'NOPOL', sortable: true },
        { key: 'year', label: 'Tahun', sortable: true },
        { key: 'color', label: 'Warna', sortable: true },
        { key: 'distributed_area', label: 'Distribusi Area', sortable: true },
        { key: 'distributed_ulp', label: 'Distribusi ULP', sortable: true },
        { key: 'distributed_kantorjaga', label: 'Distribusi Kantor Jaga', sortable: true },
        { key: 'distributed_project', label: 'Distribusi Project', sortable: true },
      ],
      fields_form :{
        variant:[],
        assurance:null,
        workshop:null,
        variant_id:null,
        number_plate:null,
        year:moment().format("Y"),
        color:null,
        bpkb_number:null,
        stnk_number:null,
        stnk_name:null,
        stnk_date:null,
        frame_number:null,
        machine_number:null,
        temporary: null,
        temporary_variant:[],
        temporary_vehicle_id: null,
      },
      model:{},
      opArea:[],
      opUlp:[],
      opKantorJaga:[],
      opProject:[],
      opAsuransi:[],
      opWorkshop:[],
      opStaff:[],
      area:null,
      ulp:null,
      kantorjaga:null,
      project:null,
      staff:null,
      branchId:null,
      vehicleId:null,
      distributed_date:moment().format('YYYY-MM-DD'),
      variantItem: [],
      change_form: false,
      is_temporary: false,
    }
  },
  mounted(){
    this.getData()
  },
  methods:{
    showVehicle(data){
      this.variant.forEach((variant, key) => {
        var vehicle = data.filter(vehicle => (vehicle.variant_id == variant.id))
        if(variant.qty == vehicle.length)
          this.variant.splice(key, 1)
      })
      this.$emit('showData', data)
      this.getData()
    },
    getData(){
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        this.model = res.data
        if(this.model.vendor_id){
          this.getAssurance(this.model.vendor_id)
          this.getWorkshop(this.model.vendor_id)
        }
        this.fetchDataArea(this.model.region.id)
        this.getStaff(this.model.region.id)
      })
      
      this.$http.get('/vehicle-variant?length=').then(res=>{
        const variant = []
        let data = res.data.data;
        for (let i = 0; i < data.length; i++) {
          let model_name = data[i].type.name + ' - ' + data[i].manufacture.name + ' - ' + data[i].model;
          variant.push({
            id: data[i].id,
            model: model_name,
          })
        }
        this.variantItem = variant
      })
    },
    getAssurance(vendor_id){
      this.$http.get('vendor/'+vendor_id+'/assurance').then(res=>{
        this.opAsuransi = res.data.data
      })
    },
    getWorkshop(vendor_id){
      this.$http.get('vendor/'+vendor_id+'/workshop').then(res=>{
        this.opWorkshop = res.data.data
      })
    },
    getStaff(region_id){
      this.$http.get('staff?filter[branch_id]='+region_id+'&filter[approval_status]=1&length=').then(res=>{
        this.opStaff = res.data.data
      })
    },
    tampilForm(){
      this.showTable = false
      this.form_title = "Tambah Item Kendaraan"
      this.valid_form = 'tambah'
      this.resetForm()
    },
    tampilTable(){
      this.showTable = true
    },
    btnSimpan(){
        this.filterDataSubmit()
        if(this.valid_form == 'tambah'){
          this.$http.post('/spb/'+this.$route.params.id+'/vehicle',this.fields_form).then(()=>{
          this.resetForm()
            this.showTable = true
            this.$refs.basetable.fetchData()
            // location.reload()
          })
        }else{
          this.$http.put('/spb/'+this.$route.params.id+'/vehicle/'+this.id_item,this.fields_form).then(()=>{
            this.resetForm()
            this.showTable = true
            this.$refs.basetable.fetchData()
            // location.reload()
          })
        }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    changeTemporary(data){
      this.is_temporary = !this.is_temporary;
      if (!this.is_temporary){
        this.fields_form.temporary = null;
      }

    },
    changeTemporaryVehicle(data){
      if (data.item.temporary == 'vehicle'){
        this.resetForm()
        this.fields_form.temporary_vehicle_id = data.item.id
        this.fields_form.variant = {
          id: data.item.variant.id,
          model: data.item.variant.type.name + ' - ' + data.item.variant.manufacture.name + ' - ' + data.item.variant.model
        }
        this.valid_form = 'tambah'
      }
      else{
        data.item.number_plate = null
        this.editForm(data)
        this.fields_form.temporary = null
      }
      this.showTable = false
      this.form_title = "Ganti Kendaraan Sementara"
      this.change_form = true;
    },
    showDistribute(data){
      this.vehicleId = data.item.id
      this.title_modal = "Konfirmasi"
      this.$refs['my-modal-distribute'].show()
    },
    hideModal(){
      this.$refs['my-modal-distribute'].hide()
    },
    submitDistribute(){
      var params = {}

      if(this.kantorjaga){
        this.branchId = this.kantorjaga.id
        params.distributed_location = this.kantorjaga.name
      }else if(this.ulp){
        this.branchId = this.ulp.id
        params.distributed_location = this.ulp.name
      }else if(this.area){
        this.branchId = this.area.id
        params.distributed_location = this.area.name
      }else{
        this.branchId = this.model.region.id
        params.distributed_location = this.model.region.name
      }

      if(this.staff){
        params.staff_id = this.staff.id
      }else{
        this.$bvToast.toast('Staff harus dipilih', {
          title: 'Distribusi Kendaraan',
          solid: true,
          variant:'danger'
        })
        return 
      }

      if(this.project)
        params.project_id = this.project.id
      else
        params.project_id = null
      
      params.branch_id = this.branchId
      params.distributed_date = this.distributed_date

      this.$http.put(this.posturl+'/'+this.$route.params.id+'/vehicle/'+this.vehicleId+'/distribute', params).then(()=>{
        this.$refs['my-modal-distribute'].hide()
        this.renderComp = false
        this.$nextTick().then(() => {
          this.renderComp = true
        })
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Distribusi kendaraan berhasil',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
   editForm(data){
    this.fields_form.variant = {
      id: data.item.variant.id,
      model: data.item.variant.type.name + ' - ' + data.item.variant.manufacture.name + ' - ' + data.item.variant.model
    }
    this.fields_form.variant_id = data.item.variant_id
    this.fields_form.workshop = data.item.workshop
    this.fields_form.assurance = data.item.assurance
    this.fields_form.number_plate = data.item.number_plate
    this.fields_form.year = data.item.year
    this.fields_form.color = data.item.color
    this.fields_form.bpkb_number = data.item.bpkb_number
    this.fields_form.stnk_number = data.item.stnk_number
    this.fields_form.stnk_name = data.item.stnk_name
    this.fields_form.stnk_date = data.item.stnk_date
    this.fields_form.frame_number = data.item.frame_number
    this.fields_form.machine_number = data.item.machine_number
    this.fields_form.temporary = data.item.temporary
    if (this.fields_form.temporary == 'vehicle' || this.fields_form.temporary == 'number_plate'){
      this.is_temporary = true;
    }
    this.fields_form.temporary_variant = data.item.temporary_variant ? {
      id: data.item.temporary_variant.id,
      model: data.item.temporary_variant.type.name + ' - ' + data.item.temporary_variant.manufacture.name + ' - ' + data.item.temporary_variant.model
    } : null;
    this.fields_form.temporary_vehicle_id = data.item.temporary_vehicle_id
    this.showTable = false
    this.form_title = "Edit Item Kendaraan"
    this.valid_form = 'edit'
    this.id_item = data.item.id
    if (data.item.temporary != 'vehicle' && data.item.temporary != 'number_plate'){
      this.change_form = true;
    }
   },
   confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm(data.item.variant != null ? 'Anda Ingin Menghapus Data '+data.item.variant.model +' ini ? Karena akan berhubungan dengan data lain ?' : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?' , {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.renderComp = false
                this.$nextTick().then(() => {
                  this.renderComp = true
                })
                this.$refs.basetable.fetchData()
            // location.reload()

            })
          }
        })

    },
    resetForm(){
      this.fields_form = {
        variant:[],
        workshop:null,
        assurance:null,
        variant_id:null,
        number_plate:null,
        year:2021,
        color:null,
        bpkb_number:null,
        stnk_number:null,
        stnk_name:null,
        stnk_date:null,
        frame_number:null,
        machine_number:null,
        temporary: null,
        temporary_variant:[],
        temporary_vehicle_id: null,
      }
      this.change_form = false;
      this.is_temporary = false;
    },
   async filterDataSubmit() {
      let newData ={}
      // console.log(this.model)
        if (this.fields_form.temporary){
          if(typeof this.fields_form.temporary_variant === 'object'){
            if(Array.isArray(this.fields_form.temporary_variant)){
              let arrData = this.fields_form.temporary_variant.map(e => e.id)
              newData[this.fields_form.temporary_variant] = arrData
            }else{
              newData['temporary_variant_id'] = this.fields_form.temporary_variant.id
              this.fields_form.temporary_variant_id = this.fields_form.temporary_variant.id
            }
          }
        }
        if(typeof this.fields_form.variant === 'object'){
          if(Array.isArray(this.fields_form.variant)){
            let arrData = this.fields_form.variant.map(e => e.id)
            newData[this.fields_form.variant] = arrData
          }else{
            newData['variant_id'] = this.fields_form.variant.id
            this.fields_form.variant_id = this.fields_form.variant.id
          }
        }

        if(this.fields_form.assurance){
          this.fields_form.assurance_id = this.fields_form.assurance.id
        }

        if(this.fields_form.workshop){
          this.fields_form.workshop_id = this.fields_form.workshop.id
        }
    },
    fetchDataArea(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-area?length=', {params:params}).then(res=>{
        this.opArea = res.data.data
      })
    },
    fetchDataUlp(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-center?length=', {params:params}).then(res=>{
        this.opUlp = res.data.data
      })
    },
    fetchDataKantorJaga(parent_id){
      var params = {}
      if(parent_id){
        params['filter[parent_id]'] = parent_id
      }
      this.$http.get('service-guard?length=', {params:params}).then(res=>{
        this.opKantorJaga = res.data.data
      })
    },
    fetchDataProject(area_id){
      this.$http.get('project?filter[area_id]='+area_id+'&length=').then(res=>{
        this.opProject = res.data.data
      })
    },
    changeSelectArea(data){
      if(data != null){
        this.fetchDataUlp(data.id)
        this.fetchDataProject(data.id);
      }else{
        this.project = null
        this.opUlp = []
        this.opKantorJaga = []
        this.opProject = []
      }
    }
    ,
    changeSelectUlp(data){
      if(data != null)
        this.fetchDataKantorJaga(data.id)
      else
        this.opKantorJaga = []
    },
  }
}
</script>
<style lang="">

</style>
